// JavaScript Document
var themeCtrl = {
	// Variables	
	didScroll: false,
	lastScrollTop: 0,
	delta: 5,
	navbarHeight: 0,
	menuaction: false,
	menuTimer: 0,
	hideStickyPost: false,
	lastUrl: '',

	// Functions
	init: function () {

		$('#menu-anchor').click(function (evt) {
			evt.preventDefault();
			$('nav.main-menu').toggleClass('show');
		});

		$(window).on("scroll", function () {
			themeCtrl.didScroll = true;
			themeCtrl.blogSharenavi();
			themeCtrl.updateActiveSection();
			themeCtrl.navbarHeight = $("header").outerHeight();
			$("#content").css('padding-top', themeCtrl.navbarHeight);
		});

		$(window).on("resize", function () {
			themeCtrl.navbarHeight = $("header").outerHeight();
			$("#content").css('padding-top', themeCtrl.navbarHeight);
		});

		themeCtrl.navbarHeight = $("header").outerHeight();
		$("#content").css('padding-top', themeCtrl.navbarHeight);
		var st = $(window).scrollTop();
		if (st > themeCtrl.navbarHeight) {
			$("header").addClass('scrolling');
		}

		$('.faq-question').click(function (evt) {
			evt.preventDefault();
			$('.faq-question.show').removeClass('show');
			$(this).addClass('show');			
		});

		setInterval(function () {
			if (themeCtrl.didScroll) {
				themeCtrl.hasScrolled();
				themeCtrl.didScroll = false;
			}
		}, 250);

		if ($('body').hasClass('home') || $('body').hasClass('page-template-template-onepager-php') || $('body').hasClass('page-template-template-offer-landingpage') ) {

			$('a.jump2NextSection').click(function (evt) {
				evt.preventDefault();
				var offsetY = $('#fullpage section').eq($(this).parent().parent().parent().index() + 1).position().top;
				$('body,html').animate({
					scrollTop: offsetY
				}, 1000, function () {});
			});

			$('a.toTop').click(function (evt) {
				evt.preventDefault();
				$('body,html').animate({
					scrollTop: 0
				}, 1000, function () {});
			});

			$('.booknow a').click(function (evt) {
				evt.preventDefault();
				themeCtrl.scrollToAnchor($(this).attr('href'));
			});
			// themeCtrl.updateActiveSection();

			$('a.jump2Section').click(function (evt) {
				evt.preventDefault();
				console.log('jump2Section');
				themeCtrl.scrollToAnchor($(this).attr('href'));
			});
			setTimeout(function () {
				themeCtrl.scrollToAnchor('', true);
			}, 30);
			

		}

		// close overlay
		$('#overlay').on('click', 'a.closeBtn', function (evt) {
			evt.preventDefault();
			//console.log("OVERLAY: hide");
			$('#overlay').removeClass("show");
		});

		themeCtrl.initZoomImage();

		// navigate in overlay mode
		$('#overlay').on('click', 'li a', function (evt) {
			evt.preventDefault();
			themeCtrl.loadPage($(this).attr('href'));
		});

		// open overlay		
		jQuery('.section a.openAsOverlay').on('click', function (evt) {
			evt.preventDefault();
			themeCtrl.loadPage($(this).attr('href'));
		});
		
		$(window).on('resize', function() { 
		});

		themeCtrl.initSlider();
	},

	blogSharenavi: function () {
		"use strict";
		//.log('check share navi');
		var scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		if ($('body').hasClass('single-post') && scrollPos > 635 && $(window).width() >= 960) {
			var offsetY = scrollPos - $("section.blogpostcontent").offset().top;

			if (offsetY > $("section.blogpostcontent").height() - $('.shariff').height()) {
				offsetY = $("section.blogpostcontent").height() - $('.shariff').height();
			}
			$('.shariff').css({
				'position': 'absolute',
				'top': offsetY
			});
		} else {
			$('.shariff').css({
				'position': '',
				'top': ''
			});
		}
	},

	hasScrolled: function () {
		"use strict";
		clearInterval(themeCtrl.menuTimer);

		var st = $(window).scrollTop();

		if (st > themeCtrl.navbarHeight) {
			$("header").addClass('scrolling');
		} else {
			$("header").removeClass('scrolling');
		}
		
		if (Math.abs(themeCtrl.lastScrollTop - st) <= themeCtrl.delta) {
			return;
		}

		themeCtrl.lastScrollTop = st;
	},

	// SCROLL TO ANCHOR ON INIT
	scrollToAnchor: function (_trgt, _init) {
		// console.log("scroll to anchor");

		//get the full url - like mysitecom/index.htm#home
		var full_url = window.location.href;

		//split the url by # and get the anchor target name - home in mysitecom/index.htm#home
		var parts = full_url.split("#");
		var trgt = parts[1];

		if (_trgt !== undefined && _trgt !== '') {
			trgt = _trgt.split("#")[1];
		}
		if (trgt !== undefined) {
			// console.log("SCROLL TO: " + trgt);
			
			
		var anchorTag = $("section[data-anchor='" + trgt + "']");
		if ( $('body').hasClass('page-template-template-offer-landingpage') ) {
			anchorTag = $("div.anchor[data-anchor='" + trgt + "']");
		}

			if (anchorTag.length === 0) {
				anchorTag = $("section").eq(0);
				trgt = '';
			}
			var sectionIndex = anchorTag.index();
			var offset = Math.round(anchorTag.offset().top);
			if (anchorTag.height() < $(window).height()) {
				// offset = anchorTag.offset().top - $(window).height() / 2 + anchorTag.height()/2;
			}
			if (sectionIndex > 0) {
				themeCtrl.currentSection = anchorTag.data('anchor');
			}

			themeCtrl.isScrolling = true;

			if (_init) {
				$('body,html').scrollTop(offset);
			} else {
				$('body,html').animate({
					scrollTop: offset
				}, 1000, function () {
					themeCtrl.isScrolling = false;
				});
			}
		}
	},

	updateActiveSection: function () {
		var viewportHeight = $(window).height();
		var scrollTop = $(window).scrollTop();
		
		if ( $('body').hasClass('page-template-template-offer-landingpage') ) {
			var full_url = window.location.href;
			var parts = full_url.split("#");
			$('#fullpage div.anchor').each(function () {
				console.log ($(this).offset().top + "/" + scrollTop + "/" + (scrollTop + viewportHeight / 2));
				if ($(this).offset().top >= scrollTop && $(this).offset().top < scrollTop + viewportHeight / 2) {
					themeCtrl.currentSection = $(this).data('anchor');
					var oldparts = themeCtrl.lastUrl.split("#");
					console.log('ANCHOR: ' + $(this).data('anchor'));
					
					if (themeCtrl.currentSection != oldparts[1]) {
						var stateObj = {
							dtpage: parts[0]
						};
						history.replaceState(stateObj, "Datentreiber", parts[0] + '#' + themeCtrl.currentSection);
						themeCtrl.lastUrl = full_url;
					}
					return false;
				}
			});
			if ( scrollTop == 0 ) {
				var stateObj = {
					dtpage: parts[0]
				};
				themeCtrl.currentSection = '';
				history.replaceState(stateObj, "Datentreiber", parts[0]);
				themeCtrl.lastUrl = full_url;
			}
		} else {
			$('#fullpage section').each(function () {
				if ($(this).offset().top >= scrollTop && $(this).offset().top < scrollTop + viewportHeight / 2) {
					themeCtrl.currentSection = $(this).data('anchor');
					var full_url = window.location.href;
					var oldparts = themeCtrl.lastUrl.split("#");
					var parts = full_url.split("#");
					if (themeCtrl.currentSection != oldparts[1]) {
						var stateObj = {
							dtpage: parts[0]
						};
						if ($("#fullpage section[data-anchor='" + themeCtrl.currentSection + "']").index() > 0) {
							history.replaceState(stateObj, "Datentreiber", parts[0] + '#' + themeCtrl.currentSection);
						} else {
							history.replaceState(stateObj, "Datentreiber", parts[0]);
						}
						themeCtrl.lastUrl = full_url;
					}
					return false;
				}
			});
		}
	},

	initZoomImage: function () {
		if ($('img.zoom-image').length > 0) {
			console.log("Init Zoom Image");
			$('body').append('<div class="zoom-image-overlay"><div class="image-frame"><div class="image-container"><img alt="" src=""></div></div><a href="#" class="close-btn">x</a></div>');

			$('.zoom-image-overlay a.close-btn').click(function (evt) {
				evt.preventDefault();
				themeCtrl.hideZoomImage();
			});

			$('img.zoom-image').click(function (evt) {
				evt.preventDefault();
				themeCtrl.showZoomImage($(this).attr('src'));
			});
		}

		$(document).keyup(function (e) {
			if (e.keyCode === 27) {
				themeCtrl.hideZoomImage();
			}
		});
	},

	showZoomImage: function (_img) {
		console.log(_img);
		$('.zoom-image-overlay').addClass('show');
		$('.zoom-image-overlay .image-container img').attr('src', _img);
		setTimeout(function () {
			var imgHeight = $('.zoom-image-overlay .image-container img').height();
			var imgWidth = $('.zoom-image-overlay .image-container img').width();
			var frameWidth = $('.zoom-image-overlay .image-frame').width();
			var frameHeight = $('.zoom-image-overlay .image-frame').height();
			var containerWidth = frameWidth + (imgWidth - frameWidth) * 2;
			var containerHeight = frameHeight + (imgHeight - frameHeight) * 2;
			if (imgWidth <= frameWidth) {
				containerWidth = imgWidth;
			}
			if (imgHeight <= frameHeight) {
				containerHeight = imgHeight;
			}

			$('.zoom-image-overlay .image-container').css({
				'width': containerWidth,
				'height': containerHeight
			});

			/*$('.zoom-image-overlay .image-container img').draggable({
				drag: function () {},
				containment: $('.zoom-image-overlay .image-container'),
				scroll: false,
				revert: false,

			});*/
			var initLeft = imgWidth - frameWidth;
			var initTop = imgHeight - frameHeight;
			if (imgWidth <= frameWidth) {
				initLeft = 0;
			}
			if (imgHeight <= frameHeight) {
				initTop = 0;
			}
			/*$('.zoom-image-overlay .image-container img').draggable("enable").css({
				'left': initLeft,
				'top': initTop
			});*/
		}, 50);

	},

	hideZoomImage: function () {
		$('.zoom-image-overlay').removeClass('show');
		$('.zoom-image-overlay .image-container img').draggable("disable");
	},

	initSlider: function () {
		$('section.wp-block-bcblock-slider').each(function() {
			for (var s = 0; s < $(this).find('.slide').length; s++) {
				if (s === 0 ) $(this).find('.slider-dots ul').append('<li class="active"><button>' + s + '</button></li>');
				else $(this).find('.slider-dots ul').append('<li><button>' + s + '</button></li>');
			}
		});
	},

	loadPage: function (_url) {
		"use strict";
		console.log("LOAD: " + _url);
		$.ajax({
			method: "POST",
			url: _url,

			success: function (result) {
				//console.log(result);
				// INSERT RESULT
				$('#overlay').addClass('show');
				var yPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
				$('#overlay').css('top', yPosition);
				$('#overlay .contentWrapper').html(result);
			},
			done: function () {},
		});
	},
};

$(document).ready(function () {
	//"use strict";	
	themeCtrl.init();
});

$(window).on('load', function () {
	//"use strict";	
	$('#globalContainer').css('opacity', '1');
});
